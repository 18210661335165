import React from 'react'
import './style.scss'

import { StaticQuery, graphql } from 'gatsby'

const UserGridData = {
  heading: 'Meet Annabel',
  subHeading: 'annabelhadfield@yahoo.co.uk',
}

const UserGrid = ({ data }) => {
  return (
    <section id="contact" className="pb-6 pt-4 mt-5 mb-3">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 meet-outline">
            <div className="row">
              <div className="col-lg-10">
                <span className="eyebrow mb-2 text-pink">
                  {UserGridData.heading}
                </span>
                <p className="lead">{UserGridData.subHeading}</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ul className="user-grid gutter-1">
                  {data.allMarkdownRemark.edges.map(({ node }) => (
                    <li key={node.id}>
                      <figure className="user">
                        <div className="user-photo expand">
                          <img src={node.frontmatter.image} alt="Avatar" />
                        </div>
                        <figcaption className="user-panel">
                          <div className="container">
                            <div className="row justify-content-between align-items-center">
                              <div className="col-lg-8">
                                <span className="eyebrow text-pink">
                                  {node.frontmatter.job}
                                </span>
                                <h4 className="h2 text-pink">
                                  {node.frontmatter.name}
                                </h4>
                                <p className="lead text-pink">
                                  {node.frontmatter.bio}
                                </p>
                              </div>
                              <div className="col-lg-4 text-pink text-lg-right">
                                <ul className="socials  text-pink">
                                  <li>
                                    <a
                                      href={node.frontmatter.facebook}
                                      className="icon-facebook fs-20 text-pink"
                                    />
                                  </li>
                                  <li>
                                    <a
                                      href={node.frontmatter.instagram}
                                      className="icon-instagram fs-20 text-pink"
                                    />
                                  </li>
                                  <li>
                                    <a
                                      href={node.frontmatter.twitter}
                                      className="icon-twitter fs-20 text-pink"
                                    />
                                  </li>
                                  <li>
                                    <a
                                      href={node.frontmatter.linkedin}
                                      className="icon-linkedin fs-20 text-pink"
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <span className="collapse" />
                        </figcaption>
                      </figure>
                    </li>
                  ))}

                  {/* <li>
                    <a href="" className="anchor rounded">
                      <span>
                        <i className="icon-arrow-right2" />
                      </span>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="separator-top pt-5 mt-5">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <p className="lead">
                <span className="text-black">We are hiring</span> new fitness
                instructors{' '}
                <a href="" className="btn btn-rounded btn-primary px-5 ml-2">
                  Apply Now
                </a>
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { category: { eq: "users" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                category
                image
                title
                job
                bio
                facebook
                twitter
                instagram
                linkedin
              }
            }
          }
        }
      }
    `}
    render={data => <UserGrid data={data} {...props} />}
  />
)
