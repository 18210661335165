import React from 'react'
import Layout from '../components/Layout/index'
import UserGrid from '../components/UserGrid/index'
import Map from '../components/Map/index'

import '../assets/css/contact.css'
import LayoutWhite from 'components/LayoutWhite/index'

export default function contact() {
  return (
    <LayoutWhite>
      <div
        id="form"
        className="container mt-6 pt-6 pb-6 mb-6 form-outline bg-white"
      >
        <div className="row">
          <div className=" col-sm-12">
            <span className="eyebrow mb-1 text-pink">Get in touch with me</span>
            <h2>I’ll get back to you as soon as possible.</h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <form
              name="contact"
              method="POST"
              action="/success"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <div className="form-row mb-1">
                <div className="col-md-4 col-sm-12">
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-minimal"
                    placeholder="Name"
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <input
                    name="email"
                    type="email"
                    className="form-control form-control-minimal"
                    placeholder="E-Mail"
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <input
                    name="phone"
                    type="text"
                    className="form-control form-control-minimal"
                    placeholder="Phone"
                  />
                </div>
              </div>
              <div className="form-row mb-1">
                <div className="col">
                  <textarea
                    name="message"
                    className="form-control form-control-minimal"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    placeholder="Your Message"
                    defaultValue={''}
                  />
                </div>
              </div>
              <div className="form-row mt-3">
                <div className="col">
                  <button type="submit" className="btn btn-pink px-5">
                    Send Mail
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Map
        isMarkerShown={true}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC63_fvMrOuJK1di-P51MxxOLrCGWay1qA&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `800px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
      <UserGrid />
    </LayoutWhite>
  )
}
